import React, { useEffect } from "react";
import "./innovation.css";
import fertyFlyAI from "../../assets/images/Fertyflys AI.svg";
import VirusImgLogo from "../../assets/images/Virus.svg";
import arrowLeft from "../../assets/images/arrow-left.svg";
import arrowRight from "../../assets/images/arrow-right.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-touch-drag-slider";

const VirusCard = (props) => {
  useEffect(() => {
    const divs = document.getElementsByClassName("switch");

    for (let index = 0; index < divs.length; index++) {
      const element = divs[index];
      element.classList.remove("current_slide");
    }
    document
      .getElementsByClassName("switch")
    [props?.current_slide]?.classList.add("current_slide");
  }, [props]);

  return (
    <div
      dir={localStorage.getItem("langue") === "ar" ? "rtl" : "ltr"}
      className="virus_card"
    >
      <h3>
        <img className="virus_logo" src={VirusImgLogo} alt="vieus_logo" />
        {props.title}
      </h3>
      <div className="">{props.text}</div>
    </div>
  );
};

const Innovation = () => {
  const [currantSlide, setCurrantSlide] = useState(0);
  const [data, setData] = useState([]);
  const [lng, i18n] = useTranslation("global");

  useEffect(() => {
    

    setData([
      {
        title: lng("innovation.card-1.title"),
        text: lng("innovation.card-1.text"),
      },
      {
        title: lng("innovation.card-2.title"),
        text: lng("innovation.card-2.text"),
      },
      {
        title: lng("innovation.card-3.title"),
        text: lng("innovation.card-3.text"),
      },
      {
        title: lng("innovation.card-4.title"),
        text: lng("innovation.card-4.text"),
      },
    ]);

    if (localStorage.getItem("langue")==="ar") {
      setCurrantSlide(3)
    }

  }, [lng]);

  const paginate = (e) => {
    if (currantSlide + e < 0) setCurrantSlide(data.length - 1);
    else if (currantSlide + e >= data.length) setCurrantSlide(0);
    else {
      setCurrantSlide(currantSlide + e);
    }
  };

  return (
    <div className="innovation_section section__padding" id="innovation">
      <div className="text_holder">
        <div className="text_holder-title">{lng("innovation.title")}</div>
        <div className="text_holder-big-title" id="id_innovation-big-title">
          {lng("innovation.sub-title")}
        </div>
        <div className="text_holder-text">{lng("innovation.text")}</div>
        <div className="virus_card_container">
          <div className="slide_btn" id="slide_btn">
            <button className="slide_btn-left" onClick={() => paginate(-1)}>
              <img src={arrowLeft} />
            </button>
            <button className="slide_btn-right" onClick={() => paginate(1)}>
              <img src={arrowRight} />
            </button>
          </div>
          <div
            dir="ltr"
            className="virus_card_slider_container"
          >
            <Slider
              activeIndex={currantSlide}

              onSlideComplete={(i) => {
                console.log(i);
                const divs = document.getElementsByClassName("switch");

                for (let index = 0; index < divs.length; index++) {
                  const element = divs[index];
                  element.classList?.remove("current_slide");
                }
                document
                  .getElementsByClassName("switch")
                [i].classList?.add("current_slide");
                setCurrantSlide(i);
              }}
            >
              {data.map(({ text, title }, index) => (
                <VirusCard
                  title={title}
                  text={text}
                  key={index}
                  img={VirusImgLogo}
                  current_slide={currantSlide}

                />
              ))}
            </Slider>
          </div>
          {/* <div id="virus_card_web_mode">
            <VirusCard
              title={data[currantSlide]?.title}
              text={data[currantSlide]?.text}
              img={VirusImgLogo}
              current_slide={currantSlide}
            />
          </div> */}
          <div className="virus_card_sliders" id="card_sliders">
            <div className="switch"></div>
            <div className="switch"></div>
            <div className="switch"></div>
            <div className="switch"></div>
          </div>
        </div>
      </div>
      <div className="img_holder">
        <img src={fertyFlyAI} alt="fertyfly_AI" />
      </div>
    </div>
  );
};

export default Innovation;
