import { useRef } from "react";
import { useInView } from "framer-motion";
import "./styles.css";

function Section({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  // const isInView_sliders = useInView(ref, { once: false });

  return (
    <section ref={ref} className="section">
      <span
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}
      >
        {children}
      </span>
    </section>
  );
}

export default Section; 

// App() {
//   return (
//     <>
//       <Section>Animate</Section>
//       <Section>when</Section>
//       <Section>in</Section>
//       <Section>view!</Section>
//     </>
//   );
// }