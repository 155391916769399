import React, { useEffect, useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import logo from "../../assets/images/logo.svg";
import marocFlag from "../../assets/images/icon-maroc.png";
import francFlag from "../../assets/images/icons-france.png";
import englishFlag from "../../assets/images/icons-english.png";
import "./navbartest.css";
import { useTranslation } from "react-i18next";


const NavBarTest = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleLngMenu, setToggleLngMenu] = useState(false);
  const [toggelToarabe, setToggelToarabe] = useState(false);
  const [currantFlag, setCurrantFlag] = useState();
  const [currantlang, setCurrantLng] = useState(localStorage.getItem("langue"));
  const [lng, i18n] = useTranslation("global");

  const handlChangLanguage = (e) => {
    i18n.changeLanguage(e);
    setToggleMenu(false);
    setToggleLngMenu(false);

    if (e === "ar") {
      setToggelToarabe(true);
      setCurrantFlag(marocFlag);
      setCurrantLng("العربية");
      document.getElementById("app-body")?.classList?.add("flex-to-arabe");
      document.getElementById("about_section")?.classList?.add("flex-to-arabe");
      document.getElementById("_form")?.classList?.add("flex-to-arabe");
      document?.getElementById("slide_btn")?.classList?.add("slide_btn-flex-to-arabe");
      document.getElementById("_form")?.classList?.add("flex-to-arabe");
      document.getElementById("card_sliders")?.setAttribute("dir", "ltr");
      document.getElementById("card_sliders")?.classList?.add("virus_card_sliders-to-arabe");
      document.getElementById("card_sliders")?.classList?.remove("virus_card_sliders");
      document.getElementById("id_innovation-big-title")?.classList?.add("innovation-big-title-arabic");
      document.getElementById("id_innovation-big-title")?.classList?.remove("text_holder-big-title");
      document.getElementById("id_innovation-big-title")?.classList?.remove("innovation-big-title-france");
    } else {
      document.getElementById("about_section")?.classList?.remove("flex-to-arabe");
      document.getElementById("app-body")?.classList?.remove("flex-to-arabe");
      document.getElementById("_form")?.classList?.remove("flex-to-arabe");
      document?.getElementById("slide_btn")?.classList?.remove("slide_btn-flex-to-arabe");
      document.getElementById("card_sliders")?.removeAttribute("dir");
      document.getElementById("card_sliders")?.classList?.add("virus_card_sliders");
      document.getElementById("card_sliders")?.classList?.remove("virus_card_sliders-to-arabe");
      if (e === "en") {
        setToggelToarabe(false);
        setCurrantFlag(englishFlag);
        setCurrantLng("English");
        document.getElementById("id_innovation-big-title")?.classList?.add("text_holder-big-title");
        document.getElementById("id_innovation-big-title")?.classList?.remove("innovation-big-title-arabic");
        document.getElementById("id_innovation-big-title")?.classList?.remove("innovation-big-title-france");
      } else {
        setToggelToarabe(false);
        setCurrantFlag(francFlag);
        setCurrantLng("Française");
        document.getElementById("id_innovation-big-title")?.classList?.add("innovation-big-title-france");
        document.getElementById("id_innovation-big-title")?.classList?.remove("text_holder-big-title");
        document.getElementById("id_innovation-big-title")?.classList?.remove("id_innovation-big-title-arabic");
      }
    }
    localStorage.setItem("langue", e);
  };

  useEffect(() => {
    if (
      localStorage.getItem("langue") === "en" ||
      localStorage.getItem("langue") === null
    ) {
      setToggelToarabe(false);
      setCurrantFlag(englishFlag);
      setCurrantLng("English");
      document.getElementById("card_sliders")?.removeAttribute("dir");
      document.getElementById("card_sliders")?.classList?.add("virus_card_sliders");
      document.getElementById("card_sliders")?.classList?.remove("virus_card_sliders-to-arabe");
      document.getElementById("_form")?.classList?.remove("flex-to-arabe");
      document.getElementById("about_section")?.classList?.remove("flex-to-arabe");
      document.getElementById("id_innovation-big-title")?.classList?.add("text_holder-big-title");
      document.getElementById("id_innovation-big-title")?.classList?.remove("innovation-big-title-arabic");
      document.getElementById("id_innovation-big-title")?.classList?.remove("innovation-big-title-france");
    } else if (localStorage.getItem("langue") === "ar") {
      setToggelToarabe(true);
      setCurrantFlag(marocFlag);
      setCurrantLng("العربية");
      document.getElementById("app-body")?.classList?.add("flex-to-arabe");
      document.getElementById("card_sliders")?.setAttribute("dir", "ltr");
      document.getElementById("card_sliders")?.classList?.add("virus_card_sliders-to-arabe");
      document.getElementById("card_sliders")?.classList?.remove("virus_card_sliders");
      document?.getElementById("slide_btn")?.classList.add("slide_btn-flex-to-arabe");
      document.getElementById("_form")?.classList?.add("flex-to-arabe");
      document.getElementById("about_section")?.classList?.add("flex-to-arabe");
      document.getElementById("id_innovation-big-title")?.classList?.add("innovation-big-title-arabic");
      document.getElementById("id_innovation-big-title")?.classList?.remove("text_holder-big-title");
      document.getElementById("id_innovation-big-title")?.classList?.remove("innovation-big-title-france");
    } else {
      setToggelToarabe(false);
      setCurrantFlag(francFlag);
      setCurrantLng("Française");
      document.getElementById("card_sliders")?.removeAttribute("dir");
      document.getElementById("card_sliders")?.classList?.add("virus_card_sliders");
      document.getElementById("card_sliders")?.classList?.remove("virus_card_sliders-to-arabe");
      document.getElementById("_form")?.classList?.remove("flex-to-arabe");
      document.getElementById("about_section")?.classList?.remove("flex-to-arabe");
      document.getElementById("id_innovation-big-title")?.classList?.add("innovation-big-title-france");
      document.getElementById("id_innovation-big-title")?.classList?.remove("text_holder-big-title");
      document.getElementById("id_innovation-big-title")?.classList?.remove("innovation-big-title-arabic");
    }
  }, [toggelToarabe]);

  return (
    <div className="__navbar section__padding" id="navbar">
      <div className="__navbar-links">
        <div className="__navbar-links_logo">
          <img alt="" src={logo} />
        </div>
        <div className="__navbar-links_container">
          <p>
            <a href="/#home">{lng("navbar.home")}</a>
          </p>
          <p>
            <a href="/#fertyfly">{lng("navbar.fertyfly")}</a>
          </p>
          <p>
            <a href="/#innovation">{lng("navbar.innovation")}</a>
          </p>
          <p>
            <a href="/#team">{lng("navbar.team")}</a>
          </p>
          <p>
            <a href="#contactUs">{lng("navbar.contactUs")}</a>
          </p>
          <p>
            <a href="aboutUs">{lng("navbar.aboutUs")}</a>
          </p>
          <p>
            <div className="lng_menu">
              <div className="lng_menu-head">
                {toggleLngMenu ? (
                  <div
                    className="lng_menu-head-div"
                    onClick={() => setToggleLngMenu(false)}
                  >
                    <img alt="" src={currantFlag} />
                    <div>{currantlang}</div>
                  </div>
                ) : (
                  <div
                    className="lng_menu-head-div"
                    onClick={() => setToggleLngMenu(true)}
                  >
                    <img alt="" src={currantFlag} />
                    <div>{currantlang}</div>
                  </div>
                )}
              </div>

              {toggleLngMenu && (
                <div className="langue_container ">
                  <div className="langue-menu_container-links scale-up-ver-top">
                    <p onClick={() => handlChangLanguage("fr")}>
                      <img alt="" src={francFlag} /> <div>francais</div>
                    </p>
                    <p onClick={() => handlChangLanguage("en")}>
                      <img alt="" src={englishFlag} />
                      <div>English</div>
                    </p>
                    <p onClick={() => handlChangLanguage("ar")}>
                      <img alt="" src={marocFlag} /> <div>العربية</div>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </p>
        </div>
      </div>
      <div className="__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#618277"
            size={27}
            onClick={() => {
              setToggleMenu(false);
              setToggleLngMenu(false);
            }}
          />
        ) : (
          <RiMenu3Line
            color="#618277"
            size={27}
            onClick={() => {
              setToggleMenu(true);
              setToggleLngMenu(false);
            }}
          />
        )}
        {toggleMenu && (
          <div className="__navbar-menu_container  scale-up-tr">
            {toggelToarabe ? (
              <div
                style={{
                  "align-items": "end",
                }}
                className="__navbar-menu_container-links"
                id="lng_menu-links"
              >
                <p>
                  <a onClick={() => setToggleMenu(false)} href="/#home">
                    {lng("navbar.home")}
                  </a>
                </p>
                <p>
                  <a onClick={() => setToggleMenu(false)} href="/#fertyfly">
                    {lng("navbar.fertyfly")}
                  </a>
                </p>
                <p>
                  <a onClick={() => setToggleMenu(false)} href="/#innovation">
                    {lng("navbar.innovation")}
                  </a>
                </p>
                <p>
                  <a onClick={() => setToggleMenu(false)} href="#team">
                    {lng("navbar.team")}
                  </a>
                </p>
                <p>
                  <a onClick={() => setToggleMenu(false)} href="#contactUs">
                    {lng("navbar.contactUs")}
                  </a>
                </p>
                <p>
                  <a onClick={() => setToggleMenu(false)} href="aboutUS">
                    {lng("navbar.aboutUs")}
                  </a>
                </p>
                <p>
                  <div className="lng_menu">
                    <div className="lng_menu-head">
                      {toggleLngMenu ? (
                        <div
                          className="lng_menu-head-div"
                          onClick={() => setToggleLngMenu(false)}
                        >
                          <img alt="" src={currantFlag} />
                          <div>{currantlang}</div>
                        </div>
                      ) : (
                        <div
                          className="lng_menu-head-div"
                          onClick={() => setToggleLngMenu(true)}
                        >
                          <img alt="" src={currantFlag} />
                          <div>{currantlang}</div>
                        </div>
                      )}
                    </div>

                    {toggleLngMenu && (
                      <div className="langue_container">
                        <div
                          className="langue-menu_container-links langue-menu_container-links-responsiv scale-up-ver-top"
                          id="lng_menu-links"
                        >
                          <p onClick={() => handlChangLanguage("fr")}>
                            <img alt="" src={francFlag} /> <div>francais</div>
                          </p>
                          <p onClick={() => handlChangLanguage("en")}>
                            <img alt="" src={englishFlag} />
                            <div>English</div>
                          </p>
                          <p onClick={() => handlChangLanguage("ar")}>
                            <img alt="" src={marocFlag} /> <div>العربية</div>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </p>
              </div>
            ) : (
              <div
                style={{
                  "align-items": "start",
                }}
                className="__navbar-menu_container-links"
                id="lng_menu-links"
              >
                <p>
                  <a onClick={() => setToggleMenu(false)} href="/#home">
                    {lng("navbar.home")}
                  </a>
                </p>
                <p>
                  <a onClick={() => setToggleMenu(false)} href="/#fertyfly">
                    {lng("navbar.fertyfly")}
                  </a>
                </p>
                <p>
                  <a onClick={() => setToggleMenu(false)} href="/#innovation">
                    {lng("navbar.innovation")}
                  </a>
                </p>
                <p>
                  <a onClick={() => setToggleMenu(false)} href="#team">
                    {lng("navbar.team")}
                  </a>
                </p>
                <p>
                  <a onClick={() => setToggleMenu(false)} href="#contactUs">
                    {lng("navbar.contactUs")}
                  </a>
                </p>
                <p>
                  <a onClick={() => setToggleMenu(false)} href="aboutUS">
                    {lng("navbar.aboutUs")}
                  </a>
                </p>
                <p>
                  <div className="lng_menu">
                    <div className="lng_menu-head">
                      {toggleLngMenu ? (
                        <div
                          className="lng_menu-head-div"
                          onClick={() => setToggleLngMenu(false)}
                        >
                          <img alt="" src={currantFlag} />
                          <div>{currantlang}</div>
                        </div>
                      ) : (
                        <div
                          className="lng_menu-head-div"
                          onClick={() => setToggleLngMenu(true)}
                        >
                          <img alt="" src={currantFlag} />
                          <div>{currantlang}</div>
                        </div>
                      )}
                    </div>

                    {toggleLngMenu && (
                      <div className="langue_container">
                        <div
                          className="langue-menu_container-links langue-menu_container-links-responsiv scale-up-ver-top"
                          id="lng_menu-links"
                        >
                          <p onClick={() => handlChangLanguage("fr")}>
                            <img alt="" src={francFlag} /> <div>francais</div>
                          </p>
                          <p onClick={() => handlChangLanguage("en")}>
                            <img alt="" src={englishFlag} />
                            <div>English</div>
                          </p>
                          <p onClick={() => handlChangLanguage("ar")}>
                            <img alt="" src={marocFlag} /> <div>العربية</div>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBarTest;
