import React from 'react'
import './fertyfly.css'
import fertyFly from '../../assets/images/fertyfly.svg'

import fertyFly1 from '../../assets/images/fertyfly_img_1.svg'
import fertyFly2 from '../../assets/images/fertyfly_img_2.svg'
import fertyFly3 from '../../assets/images/fertyfly_img_3.svg'
import { useTranslation } from 'react-i18next'



const Fertyfly = () => {
  const [lng, i18n] = useTranslation("global")

  return (
    <div className='fertyFly section__padding' id='fertyfly'>
      <p className='small_title'>{lng("fertyfly.title")}</p>
      <div className='title'>
        <div> {lng("fertyfly.sub-title.with")} </div>
        <div><img src={fertyFly} alt="" /></div>
        <div>{lng("fertyfly.sub-title.those-days-are-over")}</div>
      </div>
      <div className='fertyfly_cards'>
        <div className='fertyfly_card' >
          <div className='fertyfly_img_head'>
            <img src={fertyFly1} alt="" />
          </div>

          <p>{lng("fertyfly.card-text-1")}</p>
        </div>
        <div className='fertyfly_card' >
          <div className='fertyfly_img_head'>
            <img src={fertyFly2} alt="" />
          </div>
          <p>{lng("fertyfly.card-text-2")}</p>
        </div>
        <div className='fertyfly_card' ><div className='fertyfly_img_head'>
          <img src={fertyFly3} alt="" />
        </div><p>{lng("fertyfly.card-text-3")}</p></div>
      </div>
    </div>
  )
}

export default Fertyfly
