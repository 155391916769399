import React from 'react'
import './team.css'
import innovator_1 from '../../assets/images/innovator_1.svg'
import innovator_2 from '../../assets/images/innovator_2.svg'
import innovator_3 from '../../assets/images/innovator_3.svg'
import innovator_4 from '../../assets/images/innovator_4.svg'
import innovator_5 from '../../assets/images/innovator_5.svg'
import TeamLogo from '../../assets/images/teamLogo.svg'
import { useTranslation } from 'react-i18next'


const Teams = () => {
  const [lng,i18n] = useTranslation("global")
  return (
    <div className='team section__padding' id='team'>

            <div className='team_header' id='team-header'>
              <div className='text_holder-big-title'>{lng("team.title")}</div>
              <img src={TeamLogo} alt="team_logo" />
            </div>
            <div className='team_body'>
            <div>
              <h3 className='title'>{lng("team.sub-title")}</h3>
            </div>

            <div className='team_cards'>
              <div className='team_card' >
                <div className='team_card-head'>
                  <img src={innovator_1} alt="" />
                  </div>
                <h3>{lng("team.card-1.role")}</h3>
                <p>{lng("team.card-1.description")}</p>
              </div>

              <div className='team_card' >
                <div className='team_card-head'>
                  <img src={innovator_2} alt="" />
                  </div>
                  <h3>{lng("team.card-2.role")}</h3>
                <p>{lng("team.card-2.description")}</p>
              </div>

              <div className='team_card' >
                <div className='team_card-head'>
                  <img src={innovator_3} alt="" />
                  </div>
                  <h3>{lng("team.card-3.role")}</h3>
                <p>{lng("team.card-3.description")}</p>
              </div>

              <div className='team_card' >
                <div className='team_card-head'>
                  <img src={innovator_4} alt="" />
                  </div>
                  <h3>{lng("team.card-4.role")}</h3>
                <p>{lng("team.card-4.description")}</p>
              </div>

              <div className='team_card' >
                <div className='team_card-head'>
                  <img src={innovator_5} alt="" />
                  </div>
                  <h3>{lng("team.card-5.role")}</h3>
                <p>{lng("team.card-5.description")}</p>
              </div>
            </div> 
      </div>
    </div>
  )
}

export default Teams
