import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import globale_en from "./transtations/en/global.json";
import globale_fr from "./transtations/fr/global.json";
import globale_ar from "./transtations/ar/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("langue") ? localStorage.getItem("langue") : "en",
  resources: {
    en: {
      global: globale_en,
    },
    fr: {
      global: globale_fr,
    },
    ar: {
      global: globale_ar,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);
