import "./App.css";
import Fertyfly from "./components/fertyfly/Fertyfly";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Innovation from "./components/innovation/Innovation";
import NavBarTest from "./components/navbar/NavBarTest";
import Teams from "./components/team/Teams";
import bgHeader from "./assets/images/Background.svg";
import Section from "./Helpers/Section";
import AboutUs from "./components/about-us/AboutUs";
import { Outlet, Route, Routes } from "react-router-dom";

const AppBody = () => {
  return (
    <div className="app_header">
      <div className="header_bg ">
        <img src={bgHeader} />
      </div>
      <NavBarTest />
      <div id="app-body" className="">
        <Section>
          <Header />
        </Section>

        <Section>
          <Fertyfly />
        </Section>
        <Section>
          <Innovation />
        </Section>
        <Section>
          <Teams />
        </Section>
        <Section>
          <Footer />
        </Section>
      </div>
    </div>
  );
};

const AppLayout = () => {
  return (
    <div className="app_body app_header">
      <Outlet />
    </div>
  );
};

function App() {
  return (
    <div className="App" >
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="/" element={<AppBody />} />
          <Route path="aboutUs" element={<AboutUs />} />
          <Route path="*" element={<AppBody />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
