import React, { useState, useEffect } from "react";
import "./footer.css";
import amiaImg from "../../assets/images/amia_footer.svg";
import fixePhone from "../../assets/images/phone_fixe_logo.svg";
import mobilPhone from "../../assets/images/phone_logo.svg";
import PostImg from "../../assets/images/post_logo.svg";
import maptLogo from "../../assets/images/map_logo.svg";
import wtspLogo from "../../assets/images/wtsp_logo.svg";
import ytbLogo from "../../assets/images/ytb_logo.svg";
import pensartLogo from "../../assets/images/pensart_logo.svg";
import twetterLogo from "../../assets/images/twetter_logo.svg";
import tiktokLogo from "../../assets/images/tiktok_logo.svg";
import instaLogo from "../../assets/images/insta_logo.svg";
import fbLogo from "../../assets/images/fb_logo.svg";
import handPhone from "../../assets/images/handPhone.svg";
import wifiImg from "../../assets/images/wifi_logo.svg";
import droneImg from "../../assets/images/drone.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [name, setName] = useState("")
  const [message, setMessage] = useState("")
  const [lng, i18n] = useTranslation("global")
  useEffect(() => {
    console.log(name);
  }, [name])

  return (
    <div className="footer_section" id="contactUs">
      <div className="footer_body section__padding">
        <div className="drone"><img src={droneImg} alt="drone-image" /></div>
        <div className="wifi-img"><img src={wifiImg} alt="wifi-image" /></div>
        <div className="contact_section">
          <div className="amia_footer">
            <img src={amiaImg} alt="amia" />
          </div>
          <div className="contact">
            <div className="contact-item">
              <a href="tel:+212539916766"><img src={fixePhone} alt="socila-media" /> <div dir="ltr">05 39 91 67 66</div></a>
            </div>
            <div className="contact-item">
              <a href="tel:+212661506046"><img src={mobilPhone} alt="socila-media" /><div dir="ltr">06 61 50 60 46</div> </a>
            </div>
            <div className="contact-item">
              <a href="mailto:contact@amia.group"><img src={PostImg} alt="socila-media" /> <div>contact@amia.group</div></a>
            </div>
            <div className="contact-item">
              <a href="https://goo.gl/maps/jzbwN9q5zUTkPWrt8" target="blank"><img src={maptLogo} alt="socila-media" /><div>{lng("footer.location")}</div> </a>
            </div>
          </div>
          <div className="social_media">
            <a href=" https://wa.me/0661506046"><img src={wtspLogo} alt="social_media" /></a>
            <a href="https://www.youtube.com" target="blank"><img src={ytbLogo} alt="social_media" /></a>
            <a href="https://www.facebook.com" target="blank"><img src={fbLogo} alt="social_media" /></a>
            <a href="https://www.instagram.com" target="blank"><img src={instaLogo} alt="social_media" /></a>
            <a href="https://www.twitter.com" target="blank"><img src={twetterLogo} alt="social_media" /></a>
          </div>
        </div>
        <form id="_form" action="#" onSubmit={(e)=>{e.preventDefault()}} className="email_section">
          <div className="name_input">
            <div>{lng("footer.your-name")}</div>
            <input type="text" name="name" id="name" value={name} onChange={(e) => { setName(e.target.value) }} />
          </div>
          {/* <div>
            <div>Your email</div>
            <input type="email" name="email" id="email"  />
          </div> */}
          <div className="msg_input">
            <div>{lng("footer.massage")}</div>
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="10"
              value={message}
              onChange={(e) => { setMessage(e.target.value) }}
            ></textarea>
          </div>
          <div className="submit-btn">
            <a href={"mailto:contact@amia.group?&subject=" + name + "&body=" + message}>{lng("footer.send-msg")}</a>
          </div>
        </form>
        <div className="phone_section">
          <img src={handPhone} alt="hand-phone-img" />
        </div>
      </div>
      <div className="footer_copyRight">
        <div className="footer_copyRight-all">© {lng("footer.copyright")} <strong>AMIA</strong> </div>
        {/* <div className="footer_copyRight-terms"> Terms and conditions Privacy policy</div> */}
      </div>
    </div>
  );
};

export default Footer;
