import React from 'react'
import { useTranslation } from 'react-i18next'
import "./about-us.css";
import NavBarTest from '../navbar/NavBarTest';
import bgHeader from "../../assets/images/Background.svg";
import Footer from '../footer/Footer';
import Section from '../../Helpers/Section';

const AboutUs = () => {

    const [lng, i18n] = useTranslation("global")

    return (
        <div className=' app_header' height="200vh">
            <div className="header_bg-aboutUs ">
                <img src={bgHeader} alt='bg_img' />
            </div>
            <NavBarTest />
            <div className='aboutUs_section section__padding' id='about_section'>
                <h1>{lng("navbar.aboutUs")}</h1>
                <h2>{lng("about-us.title")}</h2>
                <div>{lng("about-us.p-1")}</div>
                <div>{lng("about-us.p-2")}</div>
                <div>{lng("about-us.p-3")}</div>
                <div>{lng("about-us.p-4")}</div>
                <div>{lng("about-us.p-5")}</div>
                <div>{lng("about-us.p-6")}</div>
                <div>{lng("about-us.p-7")}</div>
            </div>
            <Section>
                <Footer />
            </Section>
        </div>
    )
}

export default AboutUs
