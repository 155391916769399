import React from "react";
import headerImage from '../../assets/images/headerImg.svg'
import aboutIcon from '../../assets/images/about_icon.svg'
import slideDown from '../../assets/images/slideDown.svg'
import './Header.css'
import { useTranslation } from "react-i18next";

const Header = () => {

const [lng,i18n] = useTranslation("global")

  return (
    <div className="header_section section__padding" >
      <div className="header" id="header">
        <div className="header_title">
          <h1 id="head-title">{lng("header.title")}<strong className="amia">AMIA</strong> </h1>
          <p>{lng("header.sub-title")}</p>
          <a className="about_button" href="#"><img alt="about_img" src={aboutIcon} />{lng("header.btn-text")}</a>
        </div>
        <div className="header_img">
          <img src={headerImage} alt="header image" />
        </div>
        
      </div>
      <div className="slideDownBtn">
        <a href="#fertyfly"><img alt="aroow" height="60px" width="60px"  src={slideDown} /></a>
      </div>
        
    </div>
  );
};

export default Header;
